<template>
  <v-footer padless>
    <v-col class="text-center" cols="12">
      {{ new Date().getFullYear() }} —
      <strong
        ><a :href="appLandingPage" exact target="_blank">{{ appName }}</a>
      </strong>
    </v-col>
  </v-footer>
</template>
<script>
export default {
  name: "CoreFooter",
  data: () => ({
    appName: process.env.VUE_APP_NAME,
    appLandingPage: process.env.VUE_APP_LANDING_PAGE,
  }),
};
</script>